<template>
  <div class="module-two list-scroll" v-infinite-scroll="loadMore">
    <template v-if="list.length > 0">
      <div class="list-item" v-for="l in list" :key="l.id">
        <div class="list-item-left">
          <img :src="baseUrl + (l.img && l.img.file_path)" alt="" />
          <div class="item-box">
            <!-- <div class="item-name scroll-hidden">{{ l.name }}</div> -->
            <div class="item-btn" @click="toDetail(l)">
              <span>查看详情</span>
            </div>
          </div>
        </div>
        <div class="list-item-right">
          <div class="item-name scroll-hidden">{{ l.name }}</div>
          <div class="item-mes scroll-hidden">
            {{ l.mes }}
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="empty-box">
        <img src="@/assets/images/empty.jpg" alt="" />
        <p>暂无数据</p>
      </div>
    </template>
  </div>
</template>

<script>
import { getModuleBaseList } from "@/api/base.js";
export default {
  name: "ModuleTwo",
  data() {
    return {
      loadingList: false,
      searchObj: {
        page: 1,
        pageSize: 20,
        moduleCode: "",
        typeId: 0,
      },
      list: [],
      total: 0,
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        // console.log("val", val);
        const { query } = val;
        this.searchObj.moduleCode = query.module_code;
        this.searchObj.typeId = query.id;
      },
    },
    "searchObj.typeId"() {
      this.getModuleBaseList();
    },
  },
  created() {
    // console.log("created", this.searchObj);
    this.getModuleBaseList();
  },
  methods: {
    getModuleBaseList(loadMore = false) {
      if (!loadMore) {
        this.searchObj.page = 1;
        this.list = [];
      }
      this.loadingList = true;
      let params = {
        pageindex: this.searchObj.page,
        pagesize: this.searchObj.pageSize,
        module_code: this.searchObj.moduleCode,
        type_id: this.searchObj.typeId,
      };
      getModuleBaseList(params)
        .then((res) => {
          // console.log(res);
          if (res.code === 200) {
            const { data } = res;
            this.list = this.list.concat(data.data);
            this.total = data.dataCount;
          }
        })
        .finally(() => {
          this.loadingList = false;
        });
    },
    loadMore() {
      if (this.list.length < this.searchObj.page * this.searchObj.pageSize) {
        return;
      }
      this.searchObj.page += 1;
      this.getModuleBaseList(true);
    },
    toDetail(item) {
      this.$emit("clickItem", item.name);

      this.$router.push({
        name: "DetailOne",
        query: {
          module_code: this.searchObj.moduleCode,
          id: this.searchObj.typeId,
          cid: item.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .module-two {
    align-content: flex-start;
    .list-item {
      margin-top: 20px;
      min-height: 106px;

      &:last-child {
        margin-bottom: 20px;
      }
      .list-item-left {
        width: 162px;
        // .item-name {
        //   font-size: 14px;
        //   height: 28px;
        //   line-height: 28px;
        // }
      }
      .list-item-right {
        width: calc(100% - 162px);
        padding: 10px 0;

        .item-name {
          font-size: 14px;
          // height: 36px;
          // line-height: 36px;
        }

        .item-mes {
          padding: 0 10px;
          // height: calc(100% - 36px);
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .module-two {
    .list-item {
      margin-top: 10px;
      min-height: 82px;

      &:last-child {
        margin-bottom: 10px;
      }

      .list-item-left {
        width: 120px;

        // .item-name {
        //   font-size: 14px;
        //   height: 28px;
        //   line-height: 28px;
        // }
      }

      .list-item-right {
        width: calc(100% - 120px);
        padding: 10px 0;

        .item-name {
          font-size: 14px;
          // height: 30px;
          // line-height: 30px;
        }

        .item-mes {
          padding: 0 10px;
          // height: calc(100% - 30px);
        }
      }
    }
  }
}
.module-two {
  overflow-y: auto;
  .list-item {
    width: 100%;
    display: flex;

    .list-item-left {
      background: #fff;
      border: 1px solid #e4e4e4;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }

      .item-box {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        // .item-name {
        //   padding: 0 10px;
        //   font-family: SourceHanSansCN;
        //   // color: #ffffff;
        //   overflow-x: scroll;
        //   white-space: nowrap;
        //   // background: rgba(0, 0, 0, 0.4);
        //   text-shadow: 1px 1px 1px #000;
        // }

        .item-btn {
          text-align: center;
          margin-bottom: 8px;
          span {
            background: #007cf9;
            font-size: 14px;
            font-family: SimSun;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
            padding: 4px 20px;
          }
        }
      }
    }

    .list-item-right {
      border: 1px solid #daedff;

      .item-name {
          font-weight: 700;
          padding: 0 10px;
          font-family: SourceHanSansCN;
          // color: #ffffff;
          // overflow-x: scroll;
          // white-space: nowrap;
          // background: rgba(0, 0, 0, 0.4);
          // text-shadow: 1px 1px 1px #000;
        }

      .item-mes {
        margin-top: 6px;
        // text-indent: 2em;
        word-break: break-all;
        // height: 100%;
        font-size: 13px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: #4e4e4e;
        overflow: auto;
      }
    }
  }
}
</style>